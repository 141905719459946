import React, { useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n/i18n';
import { HiMenu, HiX } from 'react-icons/hi';

const navItems = [
  { path: '/', labelKey: 'navbar.home' },
  { path: '/about', labelKey: 'navbar.about' },
  { path: '/preinscription', labelKey: 'navbar.preinscription' },
  { path: '/services', labelKey: 'navbar.services' },
  { path: '/jobs', labelKey: 'navbar.jobs' },
  { path: '/activities', labelKey: 'navbar.activities' },
  { path: '/blog', labelKey: 'navbar.blog' },
  { path: '/testimonials', labelKey: 'navbar.testimonials' },
  { path: '/contact', labelKey: 'navbar.contact' }
];

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="bg-white text-sky-700 border-b border-sky-700 border-t-4 border-t-sky-900 shadow-md">
      <nav className="container mx-auto flex flex-col justify-between lg:items-center p-0">
        <div>
          <img
            src="/text-title.png"
            alt={t('home.title')}
            className="mx-auto w-64 md:w-72 lg:w-96 bg-white py-4"
          />
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-around w-full px-4 py-2">
          {/* Sección de botones (menú hamburguesa e idiomas) */}
          <section className="flex justify-between items-center w-full lg:w-auto mb-4 lg:mb-0">
            {/* Botón de menú hamburguesa */}
            <div>
              <button
                className="md:hidden p-2 rounded focus:outline-none focus:ring focus:ring-yellow-300 transition duration-300"
                onClick={toggleMenu}
                aria-label="Toggle navigation"
                aria-expanded={isOpen}
              >
                {isOpen ? (
                  <HiX className="w-6 h-6" size={30} />
                ) : (
                  <HiMenu className="w-6 h-6" size={30} />
                )}
              </button>
            </div>

            <div className="flex space-x-3 font-semibold">
              <div>
                <button
                  onClick={() => changeLanguage('es')}
                  className="transition duration-300 rounded-md shadow-sm"
                >
                  <img
                    src="/icons/es.svg" // Ruta del SVG de la bandera de España
                    alt="Cambiar a español"
                    className="w-6 h-6 hover:scale-110 transition-transform"
                  />
                </button>
              </div>
              <div>
                <button
                  onClick={() => changeLanguage('en')}
                  className="transition duration-300 rounded-md shadow-sm"
                >
                  <img
                    src="/icons/gb.svg" // Ruta del SVG de la bandera del Reino Unido
                    alt="Switch to English"
                    className="w-6 h-6 hover:scale-110 transition-transform"
                  />
                </button>
              </div>
            </div>
          </section>
          <div>
            <ul className={`w-full md:w-auto flex-col md:flex md:flex-row md:items-center md:space-x-4 text-lg font-bold transition duration-300 ${isOpen ? 'flex' : 'hidden'}`}>
              {navItems.map(({ path, labelKey }) => (
                <li key={path} className="relative group">
                  <NavLink
                    to={path}
                    className={({ isActive }) =>
                      path === '/'
                        ? `block py-2 md:py-0 px-4 rounded-md mb-2 ${isActive ? "text-red-600" : "hover:text-red-500"
                        } transition duration-300`
                        : `block py-2 md:py-0 px-4 rounded-md mb-2 ${isActive ? "text-yellow-600" : "hover:text-yellow-500"
                        } transition duration-300`
                    }
                    onClick={closeMenu}
                  >
                    {t(labelKey)}
                  </NavLink>
                  <span className="absolute bottom-0 left-0 w-full h-1 bg-red-600 scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
