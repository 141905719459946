import React, { useState } from 'react';
import { 
  Home,
  Facebook,
  Instagram,
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { FaKey } from "react-icons/fa";

const MobileNavBar = () => {
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [touched, setTouched] = useState('');

  // Manejo del feedback táctil
  const handleTouchStart = (id) => setTouched(id);
  const handleTouchEnd = () => setTouched('');

  // Verificar si un enlace está activo
  const isActive = (path) => location.pathname === path;

  // Componente NavItem para reutilizar lógica
  const NavItem = ({ to, external, icon: Icon, id }) => {
    const Component = external ? 'a' : Link;
    const props = external ? {
      href: to,
      target: "_blank",
      rel: "noopener noreferrer"
    } : { to };

    return (
      <Component
        {...props}
        className={`
          relative flex items-center justify-center
          transition-all duration-300 ease-in-out
          \${!external && isActive(to) ? 'text-green-500' : 'text-sky-800'}
          \${touched === id ? 'scale-90' : 'scale-100'}
          hover:text-green-500
          active:scale-95
          p-3
        `}
        onTouchStart={() => handleTouchStart(id)}
        onTouchEnd={handleTouchEnd}
      >
        {/* Indicador de página activa */}
        {!external && isActive(to) && (
          <span className="absolute -top-1 w-1 h-1 bg-green-500 rounded-full" />
        )}
        
        {/* Efecto de ripple en hover */}
        <div className="absolute w-12 h-12 bg-gray-100 rounded-full opacity-0 hover:opacity-20 transition-opacity duration-300" />
        
        {/* Icono */}
        <div className="relative z-10">
          <Icon size={28} strokeWidth={2} />
        </div>
      </Component>
    );
  };

  const navItems = [
    { id: 'home', to: '/', icon: Home, external: false },
    { 
      id: 'facebook', 
      to: 'https://www.facebook.com/littleallstarscarecenter', 
      icon: Facebook, 
      external: true 
    },
    { 
      id: 'instagram', 
      to: 'https://www.instagram.com/littleallstarscarecenter', 
      icon: Instagram, 
      external: true 
    }
  ];

  return (
    <>
      <nav className="
        md:hidden fixed bottom-0 left-0 right-0 
        bg-white shadow-lg border-t border-gray-100 
        backdrop-blur-sm bg-opacity-90 z-50
      ">
        <div className="grid grid-cols-4 h-16">
          {navItems.map(item => (
            <NavItem
              key={item.id}
              to={item.to}
              external={item.external}
              icon={item.icon}
              id={item.id}
            />
          ))}
        </div>

        {/* Área segura para dispositivos con notch */}
        <div className="h-safe-offset bg-white bg-opacity-90" />
      </nav>

      {/* Espaciador */}
      <div className="md:hidden h-16" />
    </>
  );
};

export default MobileNavBar;