import React from 'react';
import { Link } from 'react-router-dom';
import { FaLock, FaGraduationCap, FaUsers } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import GaleriaImagenes from '../components/GaleriaImagenes';
import RedesSocialesCTA from '../components/RedesSocialesCTA';
import { Sun, Heart, Star, MessageSquare } from 'lucide-react';
import PricingList from '../components/PricingList';

const Home = () => {
  const { t } = useTranslation();

  const handleChatbotClick = () => {
    window.open('https://ia.news724.online/chatbot/7b1c0cb0-2996-46fe-94a1-d261f9d1eabc', '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="bg-white">
      {/* Sección de bienvenida con imagen de fondo */}
      <section className="relative bg-cover bg-center min-h-[calc(100vh-4rem)] flex items-center border-b border-celeste" style={{ backgroundImage: `url('/hero.jpg')` }}>
        <div className="absolute inset-0 bottom-16 bg-white opacity-60"></div>
        <div className="container mx-auto px-4 relative z-10 text-center">
          <div className='flex flex-col md:flex-row items-center justify-center gap-4'>
            {/* Logo */}
            <div>
              <img
                src="/logoCareCenter.png"
                alt={t('home.title')}
                className="mx-auto w-64 md:w-72 lg:w-96 bg-white p-4 rounded-xl shadow-md"
              />
            </div>
            <div className="flex flex-col items-center justify-center gap-8">
              <div className='flex flex-row items-top'>
                <Star className="text-yellow-400 rotate-12" size={80} />
                <Star className="text-yellow-400" size={100} />
                <Star className="text-yellow-400 -rotate-12" size={80} />
              </div>
              <h1 className="text-lg lg:text-5xl font-extrabold mb-12 leading-tight text-sky-800">{t('home.title')}</h1>

              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Link to="/preinscription" className="bg-yellow-400 hover:bg-yellow-300 text-gray-800 py-3 px-8 rounded-full font-semibold shadow-lg transition duration-300 ease-in-out transform hover:scale-105 border-2 border-white">
                  {t('navbar.preinscription')}
                </Link>
                <Link to="https://jobs.littleallstarscarecenter.com/" className="bg-white text-yellow-600 py-3 px-8 rounded-full font-semibold shadow-lg hover:bg-yellow-100 transition duration-300 ease-in-out transform hover:scale-105 border-2 border-yellow-500">
                  {t('home.callsToAction.enrollNow')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sección de Valores */}
      <section className="py-16 bg-gray-50 border border-b-2 border-t-2 border-t-green-400 border-b-sky-400">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-sky-800 mb-12 text-center pb-4">{t('home.sections.title')}</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              { icon: <FaLock className="text-4xl mb-4 text-yellow-400" />, title: t('home.sections.safety_title'), description: t('home.sections.safety') },
              { icon: <FaGraduationCap className="text-4xl mb-4 text-yellow-400" />, title: t('home.sections.education_title'), description: t('home.sections.education') },
              { icon: <FaUsers className="text-4xl mb-4 text-yellow-400" />, title: t('home.sections.community_title'), description: t('home.sections.community') }
            ].map((value, index) => (
              <div key={index} className="bg-white p-8 shadow-lg rounded-lg transition-transform duration-300 transform hover:scale-105 flex flex-col items-center text-center border-b-8 border-b-green-500">
                {value.icon}
                <h3 className="text-xl font-semibold text-sky-800 mb-4">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className='flex flex-col lg:gap-10 lg:flex-row  items-center justify-center'>
        {/* Sección de bienvenida con imagen de fondo */}
        <section className="relative bg-cover bg-center flex items-center border-b py-12">
          <div className="absolute inset-0 bg-white opacity-80"></div>
          <div className="container mx-auto px-4 relative z-10 text-center">
            <div className='flex flex-col md:flex-row items-center justify-center gap-4'>
              <div className="flex flex-col">
                <h2 className="text-3xl md:text-4xl font-bold text-sky-600 mb-8 text-center">{t('contact.infoOrganization')}</h2>
                <p className="text-md md:text-xl max-w-2xl mx-auto mb-8 text-gray-700">{t('home.description')}</p>
                <div className="flex justify-between space-x-4">
                  <Sun className="text-yellow-400" size={100} />
                  <Heart className="text-red-500" size={100} />
                  <Star className="text-yellow-400" size={100} />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Sección de chatbot */}
        <section className="
            lg:w-1/3 p-8 bg-white rounded-2xl shadow-xl
            border border-gray-100
            flex flex-col items-center justify-center
            text-center
          ">
          <div className="
              w-20 h-20 bg-blue-500 rounded-full
              flex items-center justify-center
              mb-6
            ">
            <MessageSquare size={40} className="text-white" />
          </div>

          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            {t('contact.chatbotTitle')}
          </h2>

          <p className="text-gray-600 mb-8 max-w-md">
            {t('contact.chatbotDescription')}
          </p>

          <button
            onClick={handleChatbotClick}
            className="
                group
                relative
                inline-flex items-center justify-center
                px-8 py-4
                bg-blue-500 hover:bg-blue-600
                text-white font-medium
                rounded-full
                transition-all duration-300
                shadow-lg hover:shadow-xl
                overflow-hidden
              "
          >
            <span className="relative flex items-center">
              <MessageSquare size={20} className="mr-2" />
              {t('contact.startChatButton')}
            </span>
          </button>
        </section>
      </div>

      <section>
        <PricingList />
      </section>

      <seccion>
        <GaleriaImagenes />
      </seccion>

      <seccion>
        <RedesSocialesCTA />
      </seccion>
    </div>
  );
};

export default Home;
