// PreinscriptionForm.jsx
import { useEffect } from 'react';

const PreinscriptionForm = () => {
  // Función para inicializar el formulario externo
  useEffect(() => {
    // Crear el contenedor del formulario si no existe
    if (!document.getElementById('c1')) {
      const formContainer = document.createElement('div');
      formContainer.id = 'c1';
      document.querySelector('#form-container').appendChild(formContainer);
    }
    
    // Cargar el script de formulario externo
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//forms.parroquia.co/static_files/js/form.widget.js';
    script.onload = function() {
      try {
        // Inicializar el formulario con las opciones
        const options = {
          'id': '2lyEsw',
          'container': 'c1',
          'height': '1520px',
          'form': '//forms.parroquia.co/app/embed'
        };
        
        // Usar window para acceder a EasyForms (evita error ESLint)
        if (window.EasyForms) {
          const easyForms = new window.EasyForms();
          easyForms.initialize(options).display();
        }
      } catch (e) {
        console.error('Error al inicializar el formulario:', e);
      }
    };

    document.body.appendChild(script);

    // Limpieza al desmontar el componente
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
      
      // Remover instancias del formulario si existen
      const formContainer = document.getElementById('c1');
      if (formContainer) {
        formContainer.innerHTML = '';
      }
    };
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">    
        {/* Contenedor donde se cargará el formulario externo */}
        <div id="form-container">
          <div id="c1">
            Cargando formulario...
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreinscriptionForm;